<template>
	<div class="section">
		<div class="container">
			<div class="title is-2 is-uppercase">Update your password</div>
			<div class="columns">
				<div class="column is-one-third">
					<o-field label="Email">
						<o-input type="email" v-model="Email" placeholder="Email address"></o-input>
					</o-field>
					<o-field label="New Password" :variant="passwordsMatch ? '' : 'danger'">
						<o-input type="password" v-model="NewPassword" placeholder="New password"></o-input>
					</o-field>
					<o-field
						label="Confirm Password"
						:variant="passwordsMatch ? '' : 'danger'"
						:message="passwordsMatch ? '' : 'Your passwords must match.'">
						<o-input
							type="password"
							v-model="ConfirmPassword"
							placeholder="Confirm password"></o-input>
					</o-field>
					<br />
					<o-button
						class="is-primary"
						v-bind:class="{ 'is-loading': loading == true }"
						@click="resetPassword">
						Reset password
					</o-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	export default {
		props: {
			code: String
		},
		emits: [],
		setup(props) {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");

			const NewPassword = ref("");
			const ConfirmPassword = ref("");
			const Email = ref("");
			const loading = ref(false);

			const resetPassword = async () => {
				try {
					loading.value = true;
					var response = await $http.post("/api/user/resetPassword", {
						Email: Email.value,
						NewPassword: NewPassword.value,
						Code: props.code
					});
					$success("You have successfully reset your password.");
					global.$router.push("/login");
					loading.value = false;
				} catch (err) {
					loading.value = false;
					$error(err.response.data);
				}
			};

			const passwordsMatch = computed(() => {
				return NewPassword.value == ConfirmPassword.value;
			});

			return {
				NewPassword,
				ConfirmPassword,
				Email,
				resetPassword,
				passwordsMatch,
				loading
			};
		}
	};
</script>
